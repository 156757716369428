<!-- 港澳台及境外注册 -->
<template>
    <div class="register">
        <el-row>
            <el-col :span="24">
                <div class="top">
                    考级报名系统
                </div>
            </el-col>
            <!-- 表单 -->
            <el-col :span="24">
                <div class="content">
                    <div class="content_c">
                        <div class="jigouReg">
                            <div class="Reg">培训机构注册</div>
                            <div class="have" @click="exist">已有账号，马上去登录</div>
                        </div>
                        <!-- 表单 -->
                        <el-form ref="form" :model="form" :rules="rules" class="form" inline label-width="100px">
                            <el-form-item label="考生姓名" prop="name">
                                <el-input v-model="form.name" placeholder="请输入考生姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="拼音/英文名" prop="pinyin">
                                <el-input v-model="form.pinyin" placeholder="请输入拼音名/英文名"></el-input>
                            </el-form-item>
                            <el-form-item label="身份证号" prop="IDcard">
                                <el-input v-model="form.IDcard" placeholder="请输入身份证号"></el-input>
                            </el-form-item>
                            <el-form-item label="性别" prop="sex">
                               <div class="" style="width: 225px;">
                                   <el-radio-group v-model="form.sex">
                                       <el-radio :label="1">男</el-radio>
                                       <el-radio :label="2">女</el-radio>
                                   </el-radio-group>
                               </div>
                            </el-form-item>
                            <el-form-item label="出生日期" prop="birthday">
                                  <el-date-picker
                                    value-format="yyyy-MM-dd"
                                      v-model="form.birthday"
                                      type="date"
                                      placeholder="选择日期">
                                    </el-date-picker>
                            </el-form-item>
                            <el-form-item label="家长姓名" prop="">
                                <el-input v-model="form.Parent_name" placeholder="请输入家长姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="手机号码" prop="phone">
                                <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
                            </el-form-item>
                            <el-form-item label="民族" prop="nation">
                                <el-input v-model="form.nation" placeholder="请输入民族"></el-input>
                            </el-form-item>
                            <el-form-item label="籍贯(国籍)" prop="nationality">
                                <el-input v-model="form.nationality" placeholder="请输入籍贯(国籍)"></el-input>
                            </el-form-item>
                            <el-form-item label="地址">
                                <el-input v-model="form.address" placeholder="请输入地址"></el-input>
                            </el-form-item>
                            <el-form-item label="照片上传">
                                <el-upload class="avatar-uploader" 
                                action="https://artxt.xinyuad.net/api/public/index.php/api/common/upload"
                                    :show-file-list="false" :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload">
                                    <img v-if="imageUrl!==null" :src="this.fullurl" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </el-form-item>
                        </el-form>
                        <div class="" style="text-align: center;">
                            <span class="span_txt" style="font-size: 12px;">
                                照片要求：近期正面免冠白底一寸标准证件照，JPG文件,小于1M，像素水平413px*垂直551px
                            </span>
                        </div>
                        <!-- 注册按钮 -->
                        <div class="logon">
                            <button class="butn" type="button" @click="submitForm('form')">注册</button>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                // this.$route.query.type
                form: {
                    name: "", //姓名
                    pinyin:"",//拼音名
                    IDcard: "", //身份证号
                    sex: 1, //性别
                    birthday: "", //出生日期
                    // mechanism: {uid:'1',mechanism:"书画学院"}, //培训机构
                    Parent_name: "", //家长姓名
                    phone: "", //手机号码
                    nation: "汉族", //民族
                    nationality: "中国", //国籍
                    address: "", //地址    
                },
                // sex: "", //性别
                rules: {
                    pinyin:[{
                        required: true,
                        message: '拼音名不能为空',
                        trigger: 'blur'
                    }],
                    name: [{
                        required: true,
                        message: '考生姓名不能为空',
                        trigger: 'blur'
                    }, ],
                    IDcard: [ //验证身份证
                        {
                            validator: (rule, value, callback) => {
                                if (!value) {
                                    return callback(new Error("身份证号码不能为空"));
                                }
                                // const reg =
                                //     /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
                                // if (!reg.test(value)) {
                                //     return callback(new Error("身份证号码有误！"));
                                // }
                                callback();
                            },
                            required: true,
                            trigger: 'blur'
                        }
                    ],
                    sex: [{
                        required: true,
                        message: '性别不能为空',
                        trigger: 'blur'
                    }, ],
                    birthday: [{
                        required: true,
                        message: '出生日期不能为空',
                        trigger: 'blur'
                    }, ],
                    mechanism: [{
                        required: true,
                        message: '培训机构不能为空',
                        trigger: 'blur'
                    }, ],
                    phone: [{
                            required: true,
                            message: '手机号码不能为空',
                            trigger: 'blur'
                        },
                        {
                            required: true,
                            message: '手机号码有误!',
                            trigger: 'blur',
                            pattern:/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
                        },
                    ],
                    nation: [{
                        required: true,
                        message: '民族不能为空',
                        trigger: 'blur'
                    }, ],
                    nationality: [{
                        required: true,
                        message: '籍贯(国籍)不能为空',
                        trigger: 'blur'
                    }, ],
                },
                imageUrl: null ,//照片上传
                fullurl:"",//显示图片
                url:"",//传的图片
                bool:false
            }
        },
        created() {

        },
        methods: {
            exist() { //已有账号
                this.$router.push({
                    path: "/",
                })
            },
            submitForm(form) {
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // if(this.bool){
                        //     return
                        // }   
                        // this.bool=true
                        this.$request({url: '/api/studentexam/infoAdd', method: 'POST', data:{
                           username:this.form.name,
                           pinyin_name:this.form.pinyin,
                           idcard:this.form.IDcard,
                           gender:this.form.sex,
                           birthdate:this.form.birthday,
                           parent_name:this.form.Parent_name,
                           mobile1:this.form.phone,
                           nation:this.form.nation,
                           nation2:this.form.nationality,
                           address:this.form.address,
                           head_image: this.imageUrl?.url || '',
                           mechanism_id:localStorage.getItem('id')//机构id
                        }}).then(res=>{
                            // console.log(res)
                            if(res.code==1){
                                if(this.bool){
                                    return
                                }   
                                this.bool=true
                                this.$message({
                                    message: '注册成功',
                                    type: 'success'
                                })
                                setTimeout(() => {
                                    this.exist()
                                }, 1500)
                            }else{
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                            }
                        }).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 上传图片
            handleAvatarSuccess(res, file) {
                console.log(res, file)
                this.imageUrl = res.data;
                this.fullurl=this.imageUrl.fullurl
                this.url=this.imageUrl.url
            },
            beforeAvatarUpload(file) {
                console.log(file.type)
                const isJPG = file.type === 'image/jpg';
                const isJPEG = file.type === 'image/jpeg';
                // const isPNG = file.type === 'image/png';
                const isLt1M = file.size / 1024 / 1024 < 1;
                let flag = [isJPG, isJPEG].includes(true)
                if (!flag) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt1M) {
                    this.$message.error('上传头像图片大小不能超过 1MB!');
                }

                return flag && isLt1M;
            },
            Losefocus() { //失去焦点赋值生日性别
                // 赋值生日
                let IDcard = this.form.IDcard
                if (IDcard.trim().length == 18) {
                    // console.log(IDcard.slice(6,10)+'-'+IDcard.slice(10,12)+'-'+IDcard.slice(12,14))
                    this.form.birthday = IDcard.slice(6, 10) + '-' + IDcard.slice(10, 12) + '-' + IDcard.slice(12, 14)
                }
                // 赋值性别
                let sex = IDcard.slice(16, 17)
                if (sex) {
                    if (sex % 2 !== 0) {
                        this.form.sex = '男' //男
                        // this.sex = "1"
                    } else {
                        this.form.sex = '女' //女
                        // this.sex = "2"
                    }
                }
            },
            ConvertName(){//转换拼音
                // this.form.name
                // console.log(this.$pinyin.getFullChars(this.form.name));
                this.form.pinyin=this.$pinyin.getFullChars(this.form.name)
            }
        }
    }
</script>

<style scoped="scoped">
    @media screen and (min-width:1024px) {
        .top {
            font-size: 28px !important;
            height: 70px !important;
            line-height: 70px !important;
        }
    
        .content_c {
            width: 700px !important;
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16) !important;
        }
    
        .logon {
            width: 730px !important;
        }
    
        .butn {
            width: 383px !important;
        }
    }
    
    @media screen and (min-width:768px) {
        .top {
            font-size: 18px !important;
            height: 50px !important;
            line-height: 50px !important;
        }
    
        .content_c {
            width: 500px !important;
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16) !important;
        }
    
        .logon {
            width: 500px !important;
        }
    
        .butn {
            width: 200px !important;
        }
    }
    
    @media screen and (min-width:375px) {
        .top {
            font-size: 15px !important;
            height: 40px !important;
            line-height: 40px !important;
        }
    
        .content_c {
            width: 350px !important;
            /* box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16) !important; */
        }
    
        .logon {
            width: 350px !important;
        }
    
        .butn {
            width: 180px !important;
        }
    }
    @media screen and (min-width:320px) {
        .top {
            font-size: 14px !important;
            height: 40px !important;
            line-height: 40px !important;
        }

        .Reg {
            font-size: 14px !important;
        }

        .have {
            font-size: 12px !important;
        }

        .logon {
            width: 350px !important;
        }

        .butn {
            width: 200px !important;
        }
    }






    .register {
        width: 100%;
        height: 100%;
    }

    .top {
        width: 100%;
        height: 70px;
        text-align: center;
        line-height: 70px;
        font-size: 30px;
        background: #29726C;
        color: #fff;
    }

    .content {
        width: 100vw;
        height: 90vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content_c {
        width: 730px;
        height: 600px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        /* box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16) !important; */
    }

    .form {
        border-radius: 8px;
        display: flex;
        align-content: flex-start;
        justify-content: start;
        flex-wrap: wrap;
        margin-bottom: 25px;
    }

    .form ::v-deep .el-input__inner {
        width: 225px;
        height: 37px;
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }

    .form ::v-deep .el-form-item {
        /* margin-bottom: 20px !important; */
        height: 40px !important;
        margin-top: 0px !important;
    }

    .form ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #fff;
        color: #333333;
    }

    .jigouReg {
        width: 100%;
        height: 40px;
        line-height: 60px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .Reg {
        margin-left: 20px;
        color: #212121;
        font-size: 18px;
        font-weight: 500;
    }

    .have {
        margin-right: 20px;
        color: #707070;
        font-size: 14px;
        cursor: pointer;
    }

    /* 照片 */
    ::v-deep .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }

    .avatar {
        width: 100px;
        height: 120px;
        /* display: block; */
    }

    .span_txt {
        display: inline-block;
        width: 80%;
        margin: 0px auto;
        margin-top: 25px;
        padding-top: 20px;
    }

    /* 注册按钮 */
    .logon {
        width: 730px;
        text-align: center;
        /* margin-top: 20px; */
        /* margin-bottom: 20px; */
        height: 60px;
        line-height: 60px;
    }

    .butn {
        width: 383px;
        background: #1AAD19;
        height: 35px;
        color: #fff;
        border: none;
        cursor: pointer;
    }
</style>

